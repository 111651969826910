<template>   
   <v-row>
      <v-col cols="12">
         <h3>Report</h3>
      </v-col>
      <v-col cols="12">
         <v-card>
            <v-card-text>
               <v-row>
                  <v-col md="6" cols="12">
                     <FormControl inputType="datePicker" v-model="startDate" label="Start date" :required="true" />
                  </v-col>
                  <v-col md="6" cols="12">
                     <FormControl inputType="datePicker" v-model="endDate" label="End date" :required="true" />
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="12">
                     <v-btn color="purple darken-3 white--text" 
                        class="px-8 download-btn" 
                        large 
                        :disabled="isFetching" 
                        @click="() => getReport('voucherRedeem')">{{this.reports.voucherRedeem.name}}</v-btn>
                  </v-col>
                  <v-col cols="12">
                     <v-btn color="purple darken-3 white--text" 
                        class="px-8 download-btn" 
                        large 
                        :disabled="isFetching" 
                        @click="() => getReport('sales')">{{this.reports.sales.name}}</v-btn>
                  </v-col>
                  <v-col cols="12">
                     <v-btn color="purple darken-3 white--text" 
                        class="px-8 download-btn" 
                        large 
                        @click="() => getReport('staffPerformance')">{{this.reports.staffPerformance.name}}</v-btn>
                  </v-col>
                  <v-col cols="12">
                     <v-btn color="purple darken-3 white--text" 
                        class="px-8 download-btn" 
                        large 
                        :disabled="isFetching" 
                        @click="() => getReport('credit')">{{this.reports.credit.name}}</v-btn>
                  </v-col>
               </v-row>
            </v-card-text>
         </v-card>
      </v-col>
   </v-row>
</template>

<script>
import FormControl from '@/components/form/FormControl'
import { mapActions, mapState } from 'vuex'
import writeXlsxFile from 'write-excel-file'
import _ from 'lodash'
import moment from 'moment'

export default {
   name: 'Report',
   components: {
      FormControl
   },
   data() {
      const today = moment().format('YYYY-MM-DD')
      return {
         startDate: today,
         endDate: today,
         report: null,
         reports: {
            voucherRedeem: {
               name: 'Voucher redeem report',
               type: 'get_voucher_report',
               schema: [
                  {
                     column: 'Create date',
                     type: String,
                     value: record => moment(record.create_date).format('YYYY-MM-DD')
                  },
                  {
                     column: 'Create time',
                     type: String,
                     value: record => moment(record.create_date).format('HH:mm')
                  },
                  {
                     column: 'Username',
                     type: String,
                     value: record => record.username
                  },
                  {
                     column: 'Service',
                     type: String,
                     value: record => record.service
                  },
                  {
                     column: 'Appointment date',
                     type: String,
                     value: record => moment(record.appointment_date).format('YYYY-MM-DD')
                  },
                  {
                     column: 'Appointment time',
                     type: String,
                     value: record => moment(record.appointment_date).format('HH:mm')
                  },
                  {
                     column: 'Voucher used',
                     type: Boolean,
                     value: record => record.voucher_used
                  },
                  {
                     column: 'Status',
                     type: String,
                     value: record => record.status
                  },
               ]
            },
            sales: {
               name: 'Sales report',
               type: 'get_sales_report',
               schema: [
                  {
                     column: 'Date',
                     type: String,
                     value: record => moment(record.create_date).format('YYYY-MM-DD')
                  },
                  {
                     column: 'Time',
                     type: String,
                     value: record => moment(record.create_date).format('HH:mm')
                  },
                  {
                     column: 'Type',
                     type: String,
                     value: record => record.user_type
                  },
                  {
                     column: 'Username',
                     type: String,
                     value: record => record.username
                  },
                  {
                     column: 'Package',
                     type: String,
                     value: record => record.title
                  },
                  {
                     column: 'Amount',
                     type: Number,
                     value: record => record.price
                  }
               ]
            },
            staffPerformance: {
               name: 'Staff performance report',
               type: 'get_staff_performance',
               schema: [
                  {
                     column: 'Username',
                     type: String,
                     value: record => record.username
                  },
                  {
                     column: 'Email',
                     type: String,
                     value: record => record.email
                  },
                  {
                     column: 'Phone',
                     type: String,
                     value: record => record.phone
                  },
                  {
                     column: 'Skills',
                     type: String,
                     value: record => record.skills.join(',')
                  },
                  {
                     column: 'Service amount',
                     type: Number,
                     value: record => record.amount
                  },
                  {
                     column: 'Performance',
                     type: Number,
                     value: record => record.rate
                  },
               ]
            },
            credit: {
               name: 'Credit reward report',
               type: 'get_credit_report',
               schema: [
                  {
                     column: 'Create date',
                     type: String,
                     value: record => moment(record.create_date).format('YYYY-MM-DD')
                  },
                  {
                     column: 'Type',
                     type: String,
                     value: record => record.user_type
                  },
                  {
                     column: 'Username',
                     type: String,
                     value: record => record.username
                  },
                  {
                     column: 'Email',
                     type: String,
                     value: record => record.email
                  },
                  {
                     column: 'credit',
                     type: Number,
                     value: record => record.credit
                  }
               ]
            },
         },
      }
   },
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
   },
   methods: {
      ...mapActions(['sendRequest', 'showLoader'], 'request'),
      ...mapActions(['setDialogMessage', 'setShowDialog']),
      getReport(key) {
         console.log('getReport')
         this.report = this.reports[key]
         if (this.report) {
            this.getData()
         }
      },
      async getData() {
         console.log('getData', this.report)
         this.showLoader(true)
         const resp = await await this.sendRequest({
            type: this.report.type,
            data: {
               start_date: this.startDate,
               end_date:  this.endDate,
            },
            options: {
               noStatusSet: true
            }
         })
         if (!resp) {
            this.showLoader(false)
            this.setDialogMessage({
               message: 'Failed', 
               isError: true, 
            })
            this.setShowDialog(true)
         }
         await this.exportReport(resp.data)
         this.showLoader(false)
      },
      async exportReport(data) {
         // console.log('exportReport', data)
         return await writeXlsxFile(data, {
            schema: this.report.schema,
            fileName: `${this.report.name} - ${moment().format('YYYYMMDDHHmmss')}.xlsx`
         })
      },
   },
   async mounted() {}
}
</script>
<style lang='scss' scoped>
.download-btn {
   width: 250px;
}
</style>